<template>
  <div id="taizhang">
    <div class="content">
      <div class="box">
        <TaiZhang />
      </div>
    </div>
  </div>
</template>

<script>
import TaiZhang from "@/views/TaiZhang/taizhang";
export default {
  components: { TaiZhang }
};
</script>

<style lang="scss" scoped>
#taizhang {
  padding-top: 15vh;
}
.content {
  height: calc(100vh - 162px);
  overflow: auto;
  padding: 0 20px 20px;
}
</style>
